import React from "react";
import Heading from "../../Components/home/Heading";
import Product from '../../Components/Product/Product';
import { useTranslation } from "react-i18next";

export default function Products() {

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Məhsullar")} title={t("Əsas")} subtitle={t("Məhsullar")} />
      <Product/>
    </>
  );
}
