import React, { useEffect } from "react";
import Heading from "../../../Components/home/HeadingServices";
import AboutUs from '../../../Components/home/About/About';
import Team from "../../../Components/home/Team/Team";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { insanResurslari, muhasibatliq } from "../../../assets";
import { useTranslation } from "react-i18next";

export default function InsanResurslari() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
       <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={insanResurslari} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">{t("İnsan resurları")}</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Əsas")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{t("Xidmətlər")}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{t("İnsan resurları")}</span>
        </div>
      </div>
    </div>
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-full sml:w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
            <div className="p-6 flex flex-col justify-center ml-10">
              <h1 className="text-2xl font-bold mb-4 text-[#086170]">{t("İnsan Resursları")}</h1>
              <p className="mb-4">
                {t("İnsan resurları (HR) üzrə xidmətlərimiz aşağıdakılardır:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("İnsan resurslarının inkişafı prosesinə nəzarət, potensial risklərin müəyyən edilməsi və aradan qaldırılması")}</li>
                <li>{t("İşə qəbul, vəzifə dəyişikliyi, işdən çıxarılma, əməyin ödənişi, intizam tənbehləri, işçilərin autsorsinqi, xarici işçi qüvvəsinin cəlb edilməsi və s. daxil olmaqla, əmək qanunvericiliyi ilə bağlı istənilən məsələ üzrə məsləhət verilməsi")}</li>
                <li>{t("Kadr seçimi və işə qəbul")}</li>
                <li>{t("İşçilərin şəxsi işlərinin, iş vaxtının uçotuna nəzarət mexanizminin, işçilərin qəbulu və işdən çıxarılması ilə bağlı sənədlərin yaradılması və idarə edilməsi, və imzalanmış kadr sənədlərinin arxivdə saxlanılmasının təşkil edilməsi")}</li>
                <li>{t("Maddi məsuliyyət haqqında müqavilələrin hazırlanması")}</li>
                <li>{t("Məxfi məlumatların açıqlanmamasına dair sazişlərin hazırlanması")}</li>
                <li>{t("Bütün vəzifələr üzrə vəzifə təlimatlarının hazırlanması")}</li>
                <li>{t("Əmək müqavilələrinin şərtlərinin dəyişdirilməsi üçün hüquqi dəstək (vəzifə dəyişikliyi, iş rejiminin dəyişdirilməsi, əmək haqqı məbləğinin dəyişdirilməsi və digər prosedurlar üçün bütün lazımi sənədlərin hazırlanması)")}</li>
                <li>{t("Tələb olunan əməliyyatların e-hökumət (E-GOV) portalında işlənilməsi (e-sistemdə işəgötürənin qeydiyyatı, işçilərin ştat cədvəlinin qeydiyyatı, işə qəbul və işdən çıxarma prosedurlarının icra edilməsi)")}</li>
                <li>{t("Kadrlarla bağlı əmrlərin qeydiyyatı (mükafatların, digər ödənişlərin, əmək haqqına əlavə və mükafat ödənişlərinin verilməsi üzrə əmrlər, iş vaxtından artıq işləmək, həftəsonları işləmək ilə bağlı əmrlərin verilməsi də daxil olmaqla)")}</li>
                <li>{t("İşçilərin sosial və icbari sığortası")}</li>
                <li>{t("Əcnəbilərin işə cəlb edilməsi proseslərinin təşkil edilməsi, əcnəbilər üçün müvəqqəti və daimi yaşama icazələrinin alınması, iş icazələrinin alınması")}</li>
                <li>{t("Məzuniyyət cədvəllərinin qeydiyyatı və idarə edilməsi")}</li>
                <li>{t("Əmək qabiliyyətinin müvəqqəti itirilməsi ilə bağlı hesablamaların aparılması və tələb olunan əməliyyatların e-hökumət (E-GOV) portalına daxil edilməsi")}</li>
                <li>{t("Əmək haqqı uçotunun aparılmasında iştirak, əmək haqqı hesablamasının və əmək haqqı ödənişi ilə bağlı sənədlərin hazırlanması")}</li>
                <li>{t("Zəruri hesabatların hazırlanması və dövlət qurumlarına təqdim edilməsi")}</li>
                <li>{t("Əmək münasibətləri sahəsində dövlət qurumları ilə qarşılıqlı əlaqə")}</li>
                <li>{t("Əvvəlki dövrlər üzrə insan resursları ilə bağlı sənədlərin bərpası")}</li>
                <li>{t("İstehsalatda baş vermiş bədbəxt hadisələrin araşdırılmasında işəgötürənin təmsil edilməsi")}</li>
                <li>{t("Dövlət əmək müfəttişliyinin yoxlaması zamanı işəgötürənin maraqlarının təmsil olunması")}</li>
                <li>{t("Əmək qanunvericiliyi ilə bağlı hüquqi rəylərin verilməsi")}</li>
                <li>{t("Aşkar edilmiş pozuntular, potensial risklər, pozuntuların aradan qaldırılması üçün tövsiyələr ilə bağlı hesabatın təqdim edilməsi ilə İR sənədlərinin auditi")}</li>
                <li>{t("Autsorsinq xidmətləri")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
