import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { logo, mpost } from "../../assets/index";
import { useTranslation } from "react-i18next";


const Header = () => {
  const { t, i18n } = useTranslation();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const productsTimeoutRef = useRef(null);
  const servicesTimeoutRef = useRef(null);


  const openProductsMenu = () => {
    if (productsTimeoutRef.current) {
      clearTimeout(productsTimeoutRef.current);
    }
    setIsProductsOpen(true);
  };

  const closeProductsMenu = () => {
    productsTimeoutRef.current = setTimeout(() => { 
      setIsProductsOpen(false); 
    }, 200);
  };

  const openServicesMenu = () => {
    if (servicesTimeoutRef.current) {
      clearTimeout(servicesTimeoutRef.current);
    }
    setIsServicesOpen(true);
  };

  const closeServicesMenu = () => {
    servicesTimeoutRef.current = setTimeout(() => {
      setIsServicesOpen(false);
    }, 200);
  };
  const toggleProductsSubMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleServicesSubMenu = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleHamburgerMenu = () => setIsHamburgerMenuOpen(!isHamburgerMenuOpen);

  

  return (
    <>
      <nav className="bg-white border-b border-gray-200 py-2 ">
        <div className="w-[92%] mx-auto">
          <div className="container mx-auto flex items-center justify-between py-3 "> 
            <div>
              <a href='/'> <img src={logo} alt="Logo" className="h-16" /></a>
            </div>  
            <div className="hidden space-x-2 text-[10px] xl:text-[15px] 2xl:text-base 3xl:text-[17px] 2xl:space-x-6  lg:flex"> 
              <a href="/" className="text-[#086170] font-bold">{t("Əsas səhifə")}</a>
              <a href="/about" className="text-[#086170] font-bold">{t("Haqqımızda")}</a>
              <div 
                className="relative flex items-center"
                onMouseEnter={openServicesMenu}
                onMouseLeave={closeServicesMenu}
              >
                <button 
                  className="text-[#086170] font-bold focus:outline-none"
                  onClick={(e) => e.preventDefault()}
                >
                  {t("Xidmətlər")}
                </button>
                <button
                  onClick={() => setIsServicesOpen(!isServicesOpen)}
                  className="ml-2 focus:outline-none"
                >
                  <i className="fa-solid fa-caret-down text-[#086170]"></i> 
                </button>
                {isServicesOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-20 w-48"
                    onMouseEnter={openServicesMenu}
                    onMouseLeave={closeServicesMenu}
                  >
                    <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün xidmətlər")}</a>
                    <a href="/muhasibatliq" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Mühasibatlıq")}</a>
                    <a href="/maliyye" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Maliyyə")}</a>
                    <a href="/vergi-ucotu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Vergi uçotu")}</a>
                    <a href="/insan-resurslari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("İnsan resursları")}</a>
                    <a href="/proqramlasdirma" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Proqramlaşdırma")}</a>
                    <a href="/e-kassa" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("E-kassa")}</a>
                    <a href="/sebeke-idareetmesi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Şəbəkə idarəetməsi")}</a>
                  </div>
                )}
              </div>
              <a href="/partners" className="text-[#086170] font-bold">{t("Müştərilərimiz")}</a>
              <div 
                className="relative flex items-center"
                onMouseEnter={openProductsMenu}
                onMouseLeave={closeProductsMenu}
              >
                <button 
                  className="text-[#086170] font-bold focus:outline-none"
                  onClick={(e) => e.preventDefault()}
                >
                  {t("Məhsullar")}
                </button>
                <button
                  onClick={() => setIsProductsOpen(!isProductsOpen)}
                  className="ml-2 focus:outline-none"
                >
                  <i className="fa-solid fa-caret-down text-[#086170]"></i> 
                </button>
                {isProductsOpen && (
                  <div 
                    className="absolute top-full left-0 mt-2 bg-white border border-gray-200 shadow-lg z-50 w-48"
                    onMouseEnter={openProductsMenu}
                    onMouseLeave={closeProductsMenu}
                  >
                    <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün məhsullar")}</a>
                    <a href="/pul-qutulari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pul qutuları")}</a>
                    <a href="/all-in-one" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("All-In-One")}</a>
                    <a href="/barkod-processor" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Prosessor")}</a>
                    <a href="/barkod-oxuyucu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod oxuyucu")}</a>
                    <a href="/card-reader" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Card reader")}</a>
                    <a href="/data-terminal" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Data terminal")}</a>
                    <a href="/pos-printer" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pos printer")}</a>
                    <a href="/barkod-terezi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Tərəzi")}</a>
                  </div>
                )}
              </div>
              <a href="/program-software" className="text-[#086170] font-bold">{t("Proqram təminatı")}</a>
              <a href="/documents" className="text-[#086170] font-bold">{t("Ərizə formaları")}</a>
              <a href="/contact" className="text-[#086170] font-bold">{t("Əlaqə")}</a>
            </div>
            <div className="lg:hidden flex items-center relative ml-auto mr-10"> 
      <button className="text-[#086170] focus:outline-none" onClick={toggleHamburgerMenu}>
        <i className="fa-solid fa-bars text-[#086170]"></i>
      </button>
      {isHamburgerMenuOpen && (
        <div className={`fixed inset-y-0 left-0 transform ${isHamburgerMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out bg-white w-[390px] z-50 shadow-lg`}>
          <button className="absolute top-5 right-5 text-white bg-[#086170] p-2 rounded" onClick={toggleHamburgerMenu}>
            <FaTimes />
          </button>
          <div className="flex flex-col items-start p-8">
            <a href="/" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əsas səhifə")}</a>
            <a href="/about" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Haqqımızda")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Xidmətlərimiz")}</a>
                <button
                  onClick={toggleServicesSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isServicesOpen && (
                <div className="ml-4">
                  <a href="/services" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün xidmətlər")}</a>
                  <a href="/muhasibatliq" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Mühasibatlıq")}</a>
                  <a href="/maliyye" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Maliyyə")}</a>
                  <a href="/vergi-ucotu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Vergi uçotu")}</a>
                  <a href="/insan-resurslari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("İnsan resursları")}</a>
                  <a href="/proqramlasdirma" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Proqramlaşdırma")}</a>
                  <a href="/e-kassa" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("E-kassa")}</a>
                  <a href="/sebeke-idareetmesi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Şəbəkə idareetmesi")}</a>
                </div>
              )}
            </div>
            <a href="/partners" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Müştərilərimiz")}</a>
            <div className="relative flex flex-col w-full">
              <div className="flex items-center w-full">
                <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Məhsullar")}</a>
                <button
                  onClick={toggleProductsSubMenu}
                  className="ml-auto focus:outline-none"
                >
                  <FaChevronDown />
                </button>
              </div>
              {isProductsOpen && (
                <div className="ml-4">
                  <a href="/products" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Bütün məhsullar")}</a>
                  <a href="/pul-qutulari" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pul qutuları")}</a>
                  <a href="/barkod-processor" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Prosessor")}</a>
                  <a href="/barkod-oxuyucu" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod oxuyucu")}</a>
                  <a href="/card-reader" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Card reader")}</a>
                  <a href="/data-terminal" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Data terminal")}</a>
                  <a href="/pos-printer" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Pos printer")}</a>
                  <a href="/barkod-terezi" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Barkod Tərəzi")}</a>
                </div>
              )}
            </div>
            <a href="/program-software" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Proqram təminatı")}</a>
            <a href="/documents" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Ərizə formaları")}</a>
            <a href="/contact" className="block px-4 py-2 text-[#086170] hover:bg-gray-100">{t("Əlaqə")}</a>
            <a href='/'> <img src={mpost} alt="Logo" className="h-8 mb-2 ml-2" /></a>
            <a href='/assets/documents/Mpost.docx' download>
              <button className="bg-[#086170] text-xs text-white px-1 py-2 rounded font-bold scaling-button ml-3">
              {t("Ödənişsiz yükləyin")}
              </button>
            </a>
          </div>
        </div>
      )}
    </div> 
            <div className="hidden sml:block"> 
              <a href='/'> <img src={mpost} alt="Logo" className="h-8 mb-2" /></a>
              {/* <a href="/contact">
                <button className="bg-[#086170] text-xs text-white px-1 py-2 rounded font-bold scaling-button">
                  {t("Ödənişsiz yükləyin")}
                </button>
              </a> */}
              <a href='/assets/documents/Mpost.docx' download>
              <button className="bg-[#086170] text-xs text-white px-1 py-2 rounded font-bold scaling-button">
              {t("Ödənişsiz yükləyin")}
              </button>
            </a>

              <style jsx>{`
                @keyframes scale {
                  0%, 100% {
                    transform: scale(1);
                  }
                  50% {
                    transform: scale(1.1);
                  }
                }
                .scaling-button {
                  animation: scale 1.5s infinite ease-in-out;
                }
              `}</style>
            </div>
          </div>
        </div>
      </nav>            
    </>
  ); 
};

export default Header;



