import React from 'react'
import { useTranslation } from 'react-i18next';

const Preferences = () => {

  const { t, i18n } = useTranslation();
    const cardsData = [
        {
          title: t("Keyfiyyət"),
          description: t("İşinizin keyfiyyətli şəkildə icra olunacağından əmin ola bilərsiniz"),
          icon: <i class="fa-regular fa-handshake fa-2xl"></i> // Icon pathinizi buraya ekleyin
        },
        {
          title: t("Peşəkarlıq"),
          description: t("Layihələrimiz yüksək təcrübəyə malik peşəkarlar tərəfindən icra olunur."),
          icon: <i class="fa-solid fa-people-group fa-2xl"></i> // Icon pathinizi buraya ekleyin
        },
        {
          title: t("Konfidensiallıq"),
          description: t("Biz fərdi məlumatların konfidensiallığının qorunmasına təminat veririk."),
          icon: <i class="fa-solid fa-shield fa-2xl"></i> // Icon pathinizi buraya ekleyin
        },
      ];
  return (
    <div className="flex flex-col items-center justify-center mt-10">
       <h1 className="text-3xl font-extrabold text-center mb-5 text-[#086170] mt-10">{t("Üstünlüklərimiz")}</h1>
    <div className="flex flex-wrap justify-center">
      {cardsData.map((card, index) => (
        <div key={index} className="max-w-sm rounded overflow-hidden shadow-lg m-4 p-6 text-center border-b-4 border-[#086170]">
          <div className="flex justify-center mb-4">
            <p style={{color:'#086170'}}>{card.icon}</p>
          </div>
          <div className="font-bold text-2xl mb-2 ">{card.title}</div>
          <p className="text-gray-700 text-base">
            {card.description}
          </p>
        </div>
      ))}
    </div>
  </div>
  )
}

export default Preferences