import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {

  const { t, i18n } = useTranslation();
  return (
    <div 
      className="bg-cover bg-center bg-no-repeat flex items-center justify-center relative" 
      style={{ 
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('./contact-image.jpg')`, 
        height: '50vh',
        maxHeight: '50vh'
      }}
    >
      <div className="text-center text-white p-8 bg-opacity-50 rounded-lg">
        <h1 className="text-4xl font-bold mb-4">{t("Sizə Bir Zəng Qədər Yaxın !")}</h1>
        <p className="mb-6">{t("Nömrəmizə zəng edərək xidmətlərimiz haqqında məlumat ala bilərsiniz !")}</p>
        <a href="tel:+994552062366" className="bg-[#144375] text-white py-2 px-6 rounded-lg text-xl hover:bg-blue-700 transition duration-300">
          +994 55 206 23 66 
        </a>
      </div>
    </div>
  );
};

export default Contact;
