import React from "react";
import { footerLogo } from "../../assets/index";
import { useTranslation } from "react-i18next";

const Footer = () => {

  const { t, i18n } = useTranslation();
  return (
    <>
      <footer className="bg-gray-900 text-gray-300 py-20">
        <div className="w-[90%] mx-auto">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-20">
            <div className="flex flex-col items-center md:items-start">
              <img src={footerLogo} alt="Inteko MMC Logo" className="mb-6 w-60 h-24 " />
              <div className="flex space-x-8 mt-8">
                <a href="https://www.instagram.com/inteko.az" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-instagram text-2xl"></i></a>
                <a href="#" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-facebook text-2xl"></i></a>
                <a href="#" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-linkedin text-2xl"></i></a>
                <a href="#" className="text-[#086170] hover:text-blue-400 transition duration-300"><i className="fab fa-tiktok text-2xl"></i></a>
              </div>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Sürətli Keçidlər")}</h3>
              <ul className="space-y-4">
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Əsas səhifə")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/about" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Haqqımızda")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}}  className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/services" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Xidmətlər")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/products" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Məhsullar")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/partners" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Müştərilərimiz")}</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Xidmətlərimiz")}</h3>
              <ul className="space-y-4">
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/muhasibatliq" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Mühasibatlıq")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/maliyye" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Maliyyə")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/vergi-ucotu" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Vergi uçotu")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/insan-resurslari" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("İnsan resursları")}</a>
                </li>
                <li>
                  <i style={{color:'#086170'}} className="fa-solid fa-chevron-right mr-3"></i>
                  <a href="/proqramlasdirma" className="hover:underline hover:text-gray-100 transition duration-300 text-lg">{t("Proqramlaşdırma")}</a>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center md:items-start">
              <h3 className="text-white font-bold mb-8 text-2xl">{t("Əlaqə Vasitələri")}</h3>
              <address className="not-italic space-y-4 text-lg">
                <p><i style={{color:'#086170'}} className="fa-solid fa-location-dot mr-3"></i> {t("Bakı şəhəri, 11 Z Xəlil, Baku 1001")}</p>
                <p><i style={{color:'#086170'}} className="fa-solid fa-envelope mr-3"></i><a href="mailto:info@inteko.az" className="hover:underline hover:text-gray-100 transition duration-300">info@inteko.az</a></p>
                <p><i style={{color:'#086170'}} className="fa-solid fa-phone-flip mr-3"></i><a href="tel:+994552062366" className="hover:underline hover:text-gray-100 transition duration-300">+994 55 206 23 66</a></p>
              </address>
            </div>
          </div>
          <div className="container mx-auto text-center mt-12 border-t border-gray-700 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p>{t("2024 © Müəllif hüquqları qorunur.")}</p>
            <a href="https://www.birsayt.az"><p>{t("Saytın hazırlanması")}: <a href="#" className="hover:underline hover:text-gray-100 transition duration-300">Birsayt.az</a></p></a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
