

import React from 'react';
import { bgPhoto } from '../../assets/index';

const Heading = ({ heading, title, title2, subtitle }) => {
  return (
    <div className="relative bg-gray-900 bg-opacity-80 text-white h-80 flex flex-col items-center justify-center">
      <img src={bgPhoto} alt="Background" className="absolute inset-0 w-full h-full object-cover opacity-40" />
      <div className="relative z-10 text-center px-4">
        <h1 className="text-5xl font-extrabold mt-2 tracking-tight">{heading}</h1>
        <div className='mt-5 flex items-center justify-center space-x-2'>
        <i class="fa-solid fa-house"></i>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{title}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <a href='/' className="text-sm uppercase tracking-wide text-orange-300 hover:text-red-700 transition-colors">{title2}</a>
          <span className="text-sm uppercase tracking-wide text-gray-300">/</span>
          <span className="text-sm uppercase tracking-wide text-gray-300">{subtitle}</span>
        </div>
      </div>
    </div>
  );
};

export default Heading;

