import React from 'react'
import Heading from "../../Components/home/HeadingServices";
import { useTranslation } from 'react-i18next';

const DetailedPage = () => {

  return (
    <Heading heading="DetailedPage" title="Əsas" title2='Xidmətlər' subtitle="DetailedPage" />
  )
}

export default DetailedPage