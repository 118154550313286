import React, { useEffect, useState } from "react";
import Heading from "../../../Components/home/HeadingProducts";
import { useTranslation } from "react-i18next";

export default function BarkodProcessor() {
  const { t, i18n } = useTranslation();

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.inteko.az/inteko/dashboard.php/",
        {
          credentials: 'omit'
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data.filter(item => item.category === "Barkod processor"));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading heading={t("Barkod Prosessor")} title={t("Əsas")} title2={t('Məhsullar')} subtitle={t("Barkod Prosessor")} />
      <div className='container w-[80%]'>
        {isLoading ? (
          // Yükleme esnasında gösterilecek gif
          <div className="flex justify-center items-center h-96">
            <img src="/loading.gif" alt="Loading..." className="w-24 h-24" />
          </div>
        ) : (
          // Yükleme tamamlandığında gösterilecek içerik
          <div className='content grid3'>
            {list.map((val, index) => {
              const { id, image, category, name, about, price } = val;
              return (
                <div className='box shadow' key={index}>
                  <div className='img'>
                    <img src={`data:image/jpeg;base64,${image}`} alt={name} />
                  </div>
                  <div className='text'>
                    <div className='category flex'>
                      <span>{t(category)}</span>
                      <i className='fa fa-heart'></i>
                    </div>
                    <h4>{name}</h4>
                    <h4 className="text-lg font-bold text-green-600 mb-4 mt-2 animate-blink">Stokda var</h4>
                    <a href={`/product-detail/${id}`}><button className='details-button'>{t("Ətraflı")}</button></a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}
