import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { bgPhoto, muhasibatliq } from "../../assets/index";
import { useTranslation } from "react-i18next";
import Heading from "../../Components/home/Heading";

export default function ProgramSoftware() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <>
     <Heading heading={t("Proqram təminatı")} title={t("Əsas")} subtitle={t("Proqram təminatı")} />
      <div className="flex flex-col items-center mt-12 mb-8">
      <div className="w-full sml:w-[80%]">
          <div data-aos="fade-up" className="flex flex-col md:flex-row rounded-lg p-6">
            <div className="p-6 flex flex-col justify-center ml-10">
              <h1 className="text-2xl font-bold mb-4 text-[#086170]">{t("Mpos Proqram təminatı üzrə veriləcək xidmətlərin spesifikasiyası")}</h1>
              <p className="mb-4">
                {t("Sizə İNTEKO şirkəti Sizə “Mpos” satış və anbar proqram təminatının - yeddi arxitekturalı ( satışın idarə edilməsi, alışların idarə edilməsi, anbar ehtiyatlarının idarə edilməsi, xərclərin idarə edilməsi, proqram təminatının idarə edilməsi, pul vəsaitlərinin idarə edilməsi, inteqrasiya və texniki imkanlar və bir kompyuter üzərindən digər kompyuterləri idarə etmə) imkanı və proqram təminatı sistemi üzrə yeni nəsil elektron kasalara inteqrasiyanı təmin edilməsini təklif edir.")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Proqram təminatı aşağıdakı funksionallıqları təmin etməklə həm satış uçotu həm də reqlamentləşdirilmiş anbar qalıqlarının uçotunu təmin edir.")}</li>
                <li>{t("Proqram təminatının interfeysi tam Azərbaycan dilindədir.")}</li>
                <li>{t("Proqram təminatının müştəri səviyyəsi Alıcının təqdim etdiyi avadanlıq (kompyuter və digər qurğullar ) ilə işləmə imkanı vardır.")}</li>
                <li>{t("Sistemin 96% istifadə mümkünlüyünü təmin etmək üçün ikili şifrələnmə sitemindən istifadə olunur. İstifadəçinin istəyinə uyğun olaraq təyin olunmuş şəxslər tərəfindən idarə edilməsi təmin edilir. İstifadəçi şifrələrinin qurulmasında mürəkkəb strukturun tələbindən istifadə edilir.")}</li>
                <li>{t("Proqram quraşdırıldıqdan sonra İcraçı tərəfindən aylıq texniki dəstək xidməti göstərilir.")}</li>
                <li>{t("İcraçı həmçinin proqramdan istifadə ilə bağlı Alıcının əməkdaşlarına təlim xidməti göstərir.")}</li>
                <li>{t("İcracı proqram təminatı üzrə Alıcı ilə bağlanmış müqaviləyə əsasən sifarişə uyğun olaraq istifadəçi lisenziyasının müqavilə üzrə verilməsini təmin edir.")}</li>
                <li>{t("Proqramın quraşdırılması üçün tələb olunan minimum server və avadanlıqların təmin olunması spesifikasiyaları təklif sənədlərində göstərilibdir.")}</li>
                <li>{t("Alıcıda texniki problem yaranarsa yazılı müraciətdən 24 saatdan gec olmayaraq texniki servisin göstərilməsi.")}</li>
                <li>{t("Ayda 1 dəfədən az olmayaraq texniki baxışın keçirilməsi.")}</li>
                <li>{t("Proqram təminatının avadanlıqlara qoşulmasının icra qrafiki cəmi 30 dəqiqə təşkil edir")}</li>
              </ul>
              <p className="mb-4">
                {t("“Mpos” anbar və satış proqramının funksionallıq sistemi aşağıdakı biznes funksionallığını təmin edir:")}
              </p>
              <p className="mb-4">
                1.{t("Satışın idarə edilməsi, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Alıcı adları, ünvanları və onların müqavilələrinin əlavəsi")}</li>
                <li>{t("Qiymət qoyma")}</li>
                <li>{t("Güzəştlər")}</li>
                <li>{t("Alıcı sifarişlərinin idarə edilməsi")}</li>
                <li>{t("Satış prosesinin dəstəklənməsi")}</li>
                <li>{t("Qarşılıqlı hesablaşmaların idarə edilməsi")}</li>
                <li>{t("Satış üzrə hesabatları")}</li>
                <li>{t("Müvafiq istifadə edilən sistem sənədlərin çap formaları")}</li>
              </ul>
              <p className="mb-4">
                2.{t("Alışların idarə edilməsi, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Təchizatçı qiymətlərinin qeydə alınması")}</li>
                <li>{t("Təchizatçıya sifarişlərin idarə edilməsi")}</li>
                <li>{t("Nisiyə malların verilməsi və ona nəzarət")}</li>
                <li>{t("Daxilli kredit")}</li>
                <li>{t("Alış prosesinin dəstəklənməsi")}</li>
                <li>{t("Qarşılıqlı hesablaşmaların idarə edilməsi")}</li>
                <li>{t("Təchizatçıya malların qaytarılması")}</li>
                <li>{t("Təchizatla bağlı əlavə xərcləri maya dəyərində uçota alınması")}</li>
                <li>{t("Alış üzrə hesabatları")}</li>
                <li>{t("Müvafiq istifadə edilən sistem sənədlərin çap formaları")}</li>
              </ul>
              <p className="mb-4">
                3.{t("Anbar ehtiyatlarının idarə edilməsi, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Məhsulların ölçü vahidləri")}</li>
                <li>{t("Mədaxili və məxarici")}</li>
                <li>{t("Daxili sifariş")}</li>
                <li>{t("Anbar ehtiyatları üzrə hesabatları")}</li>
                <li>{t("Müvafiq istifadə edilən sistem sənədlərin çap formaları")}</li>
          
              </ul>
              <p className="mb-4">
                4.{t("Xərclərin idarə edilməsi, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Xidmətlərin alışı")}</li>
                <li>{t("Maya dəyərinin hesablanması")}</li>
                <li>{t("Xərclərin idarə edilməsi üzrə hesabatları")}</li>
                <li>{t("Müvafiq istifadə edilən sistem sənədlərin çap formaları")}</li>
      
              </ul>
              <p className="mb-4">
                5.{t("Proqram təminatının idarə edilməsi, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("İstifadəçilərin təyin olunması (adminstrator, satıcı, xəzinədar, anbardar və s.)")}</li>
                <li>{t("İstifadəçilərin planlaşdırılması –")}</li>
                <li>{t("İstifadəçilərin yerdəyişməsi və hüquqların verilməsi")}</li>
                <li>{t("İstifadəçilərin nəzarət kassalar üzrə bölgüsü")}</li>
                <li>{t("Müvafiq istifadə edilən sistem sənədlərin çap formaları")}</li>
      
              </ul>
              <p className="mb-4">
                6.{t("Pul vəsaitlərinin idarə edilməsi, o cümlədən:")}
              </p> 
              <ul className="list-disc list-inside mb-4">
                <li>{t("Nağd pul vəsaitlərinin idarə edilməsi")}</li>
                <li>{t("Kassalar - Pul vəsaitinin məxarici")}</li>
                <li>{t("Pul vəsaitinin mədaxili")}</li>
                <li>{t("Nağdsız pul vəsaitlərin idarə edilməsi")}</li>
                <li>{t("Posterminal ödənişlərinin hesabatı")}</li>
                <li>{t("Ayın sonu nəticələrinin əldə edilməsi (ay yekunu) – İlin yekunu")}</li>
                <li>{t("Satışlar haqda hesabat (bütün tarix aralığı üzrə)")}</li>
  
              </ul>
              <p className="mb-4">
                7.{t("İnteqrasiya və texniki imkanlar, o cümlədən:")}
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>{t("Nəzarət kassa aparatlaraına inteqrasiya")}</li>
                <li>{t("Günü aç, günü bağla funksiyasının (Z hesabatı) proqramdan idarə olunması")}</li>
                <li>{t("Qaytarma əməliyyatları proqraman izləmə və idarə etmə imkanı")}</li>
                <li>{t("Vaxtı keçən məhsullar barədə xəbərdarlıq")}</li>
                <li>{t("Defisit və ya bitməkdə olan məhsullar haqda xəbərdarlıq funksiyası")}</li>
                <li>{t("Kreditin verilməsi-( kimə, tarix,ödəniş cədvəli və aylıq ödnişin əlavəsi)")}</li>
              </ul>
              <p className="mb-4">
                {t("Proqram təminatı sifarişdən asılı olaraq eyni anda 2 və daha çox kompyuteri mərkəz baza olaraq birləşdirir ki, buda Sizə eyni anda anbarlar arası satışların idarə olunması və anbar qalıqlarının eyni anda azalma və çoxalması imkan yaradır.")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
