import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About'; 
import Services from "../../Components/Services/Services";
import { useTranslation } from "react-i18next";

export default function About() {

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Xidmətlərimiz")} title={t("Əsas")} subtitle={t("Xidmətlərimiz")} />
      <Services/>
    </>
  );
}
