import React, { useState } from 'react'
import Heading from "../../Components/home/Heading";
import { useTranslation } from 'react-i18next';
import { word } from '../../assets';


const files = [
  { name: 'Alqı satqıya dair Ərizə forması 1', url: '/assets/documents/document1.docx' },
  { name: 'Audit - Aktı', url: '/assets/documents/document2.docx' },
  { name: 'Balansarxası hesablarda uçota alınan qiymətlər haqqında', url: '/assets/documents/document3.docx' },
  { name: 'Başqa işə keçirilmə razılıq', url: '/assets/documents/document4.doc' },
  { name: 'Başqa işə keçirilmə xahiş', url: '/assets/documents/document5.doc' },
  { name: 'Borcların ilkin qiymətləndirilməsi üçün - Ərizə', url: '/assets/documents/document6.docx' },
  { name: 'Daxili nizam-intizam qaydaları', url: '/assets/documents/document7.doc' },
  { name: 'Doğum faktının tanınmasının tələbinə dair', url: '/assets/documents/document8.docx' },
  { name: 'Ezamiyyət - ƏMR', url: '/assets/documents/document9.docx' },
  { name: 'Əlavə iş yerindən əsasə keçid', url: '/assets/documents/document10.doc' },
  { name: 'Əmanətlərin sığortalanması - Ərizə', url: '/assets/documents/document11.docx' },
  { name: 'Əmək haqqı hesablama cədvəli - 2', url: '/assets/documents/document12.docx' },
  { name: 'Əmək kitabçası açılması', url: '/assets/documents/document13.docx' },
  { name: 'Əmək məzuniyyətinin başqa vaxtda keçirilməsi', url: '/assets/documents/document14.docx' },
  { name: 'Əmək mühavizəsi üzrə görülən işlərin uçot jurnalı', url: '/assets/documents/document15.docx' },
  { name: 'Əməyin mühavizəsi üzrə mühəndis göstərişi', url: '/assets/documents/document16.docx' },
  { name: 'Əmlak - Özəlləşdirməsi barədə ərizə', url: '/assets/documents/document17.doc' },
  { name: 'Əmlak tələninə dair', url: '/assets/documents/document18.docx' },
  { name: 'Əmlaka vurulmuş zərərin əvəzinin ödənilməsinə dair', url: '/assets/documents/document19.docx' },
  { name: 'Əsas iş yerindən əlavəyə keçmək', url: '/assets/documents/document20.doc' },
  { name: 'Əvəz etməyə razılıq', url: '/assets/documents/document21.doc' },
  { name: 'Hərracda müsabiqədə iştirak üçün ərizə', url: '/assets/documents/document22.doc' },
  { name: 'Həvalə ərizəsi - boş vəzifə üzrə əmək funksiyaların icrası', url: '/assets/documents/document23.docx' },
  { name: 'Hüquq departamentinin əsasnaməsi', url: '/assets/documents/document24.docx' },
  { name: 'Hüquq şöbəsnin əsasnaməsi', url: '/assets/documents/document25.docx' },
  { name: 'İntizam-tənbeh - ƏMR', url: '/assets/documents/document26.docx' },
  { name: 'İş saatından artıq işləmək razılıq', url: '/assets/documents/document27.doc' },
  { name: 'İş stajı və əmək haqqı barədə - Arayış', url: '/assets/documents/document28.docx' },
  { name: 'İşçinin mükafatlarıdılması barədə', url: '/assets/documents/document29.docx' },
  { name: 'İşdən azad edilmə ərizəsi 1-ci forma', url: '/assets/documents/document30.docx' },
  { name: 'İşdən azad edilmə ərizəsi 2-ci forma', url: '/assets/documents/document31.docx' },
  { name: 'İşdən azad olma ərizəsi', url: '/assets/documents/document32.doc' },
  { name: 'İşdən viza üçün arayış forması', url: '/assets/documents/document33.docx' },
  { name: 'İşə gedib gəlmək jurnalı', url: '/assets/documents/document34.docx' },
  { name: 'İşə gəlməmək üçün icazə ərizəsi', url: '/assets/documents/document35.docx' },
  { name: 'İşə qəbul ərizəsi - forma 1', url: '/assets/documents/document36.docx' },
  { name: 'İşə qəbul ərizəsi - forma 2', url: '/assets/documents/document37.docx' },
  { name: 'İşə qəbul ərizəsi - forma 3', url: '/assets/documents/document38.docx' },
  { name: 'İşə qəbul ərizəsi sınaq müddətilə', url: '/assets/documents/document39.docx' },
  { name: 'İşə qəbul ərizəsi', url: '/assets/documents/document40.docx' },
  { name: 'istinadlar------------------------------', url: '/assets/documents/document41.docx' },
  { name: 'Kadrlar şöbəsinin əsasnaməsi', url: '/assets/documents/document42.docx' },
  { name: 'Kassa Kitabı', url: '/assets/documents/document43.docx' },
  { name: 'Keyfiyyətə nəzarət şöbəsinin əsasnaməsi', url: '/assets/documents/document44.docx' },
  { name: 'Kompensasiya verilməsinə razılıq', url: '/assets/documents/document45.doc' },
  { name: 'Kotirovka sorğusu', url: '/assets/documents/document46.docx' },
  { name: 'Kredit üzrə borcun qaytarılması barədə', url: '/assets/documents/document47.docx' },
  { name: 'Mal göndərənin katirofka təklifi', url: '/assets/documents/document48.docx' },
  { name: 'Maliyyə nəticələri və istifadəsi hesabatı', url: '/assets/documents/document49.docx' },
  { name: 'Maliyyə şöbəsinin əsasnaməsi', url: '/assets/documents/document50.docx' },
  { name: 'Malların qeyd alınması kitabı', url: '/assets/documents/document51.docx' },
  { name: 'Malların qiymət cədvəli', url: '/assets/documents/document52.docx' },
  { name: 'Məhkumlu arayışı - xaricdə yaşayanlar üçün', url: '/assets/documents/document53.docx' },
  { name: 'Mənzilin satılmasına razılıq ərizəsi', url: '/assets/documents/document54.docx' },
  { name: 'Məzuniyyət dövrünün birləşdirilməsi', url: '/assets/documents/document55.doc' },
  { name: 'Məzuniyyət ərizəsi', url: '/assets/documents/document56.doc' },
  { name: 'Məzuniyyətdən geri çağırılma_tutulma', url: '/assets/documents/document57.docx' },
  { name: 'Məzuniyyətin uzadılması barədə - Ərizə', url: '/assets/documents/document58.docx' },
  { name: 'Məzuniyyətlə bağlı kompensasiya', url: '/assets/documents/document59.docx' },
  { name: 'Müqavilənin dəyişdirilməsinə dair iddia', url: '/assets/documents/document60.docx' },
  { name: 'Müsabiqə işə qəbul Elektron-ərizə-forması', url: '/assets/documents/document61.doc' },
  { name: 'Nəqliyyat vasitələrinin zədələnməsinə dair', url: '/assets/documents/document62.docx' },
  { name: 'Növbəti səyyar vergi yoxlamasının keçirilməsinə dair bildiriş', url: '/assets/documents/document63.docx' },
  { name: 'Ödənişsiz məzuniyyət - ƏMR', url: '/assets/documents/document64.docx' },
  { name: 'Ödənişsiz məzuniyyət (öz hesabına) ərizəsi', url: '/assets/documents/document65.docx' },
  { name: 'Öz hesabına məzuniyyət ərizəsi', url: '/assets/documents/document66.docx' },
  { name: 'Özəlləşdirməyə razılıq barədə Ərizə', url: '/assets/documents/document67.docx' },
  { name: 'Pul tələbinə dair', url: '/assets/documents/document68.docx' },
  { name: 'Qeyri iş günü işləmək_razılıq ərizəsi', url: '/assets/documents/document69.doc' },
  { name: 'Qeyri rezident hüququ şəxsin filialının əsasnaməsi', url: '/assets/documents/document70.docx' },
  { name: 'QHT-Ərizəsi', url: '/assets/documents/document71.doc' },
  { name: 'Qismən ödənişli sosial məzuniyyət ərizəsi', url: '/assets/documents/document72.doc' },
  { name: 'Qiyabı qətnaməsinin ləğv edilməsi barədə - Ərizə', url: '/assets/documents/document73.docx' },
  { name: 'Qrant müqaviləsinin qərarının qeydə alınmasə - Ərizə', url: '/assets/documents/document74.docx' },
  { name: 'Sənədin əslinin qaytarılması barədə ərizə', url: '/assets/documents/document75.docx' },
  { name: 'Şəxsin məhkum olması barədə arayış', url: '/assets/documents/document76.docx' },
  { name: 'Səyyar vergi yoxlamasə aktı', url: '/assets/documents/document77.doc' },
  { name: 'Sosial məzuniyyət ərizəsi - doğuşdan sonra', url: '/assets/documents/document78.docx' },
  { name: 'Sosial məzuniyyət ərizəsi - hamiləliyə görə', url: '/assets/documents/document79.docx' },
  { name: 'Sosial məzuniyyət ərizəsi', url: '/assets/documents/document80.doc' },
  { name: 'Sosial məzuniyyətə çıxmaqla bağlı ərizə', url: '/assets/documents/document81.docx' },
  { name: 'Soyadın dəyişdirilməsinə dair - Ərizə', url: '/assets/documents/document82.docx' },
  { name: 'Təhsil məzuniyyət ərizəsi', url: '/assets/documents/document83.docx' },
  { name: 'Təhsil məzuniyyətiərizəsi', url: '/assets/documents/document84.doc' },
  { name: 'Təhvil təslim aktı icarə', url: '/assets/documents/document85.doc' },
  { name: 'Tələbin həcminin artırılması', url: '/assets/documents/document86.docx' },
  { name: 'Tikinti layihəsinin təqdim olunması', url: '/assets/documents/document87.docx' },
  { name: 'Tikinti quraşdırma işlərinin ümumi jurnalı', url: '/assets/documents/document88.doc' },
  { name: 'Töhmət verilməsi barədə - ƏMR', url: '/assets/documents/document89.docx' },
  { name: 'Torpaq sahəsnin sənədləşdirilməsinə dair razılıq ərizəsi', url: '/assets/documents/document90.docx' },
  { name: 'Üzləşmə - Aktı', url: '/assets/documents/document91.docx' },
  { name: 'Valideynin yazılı razılığı', url: '/assets/documents/document92.docx' },
  { name: 'Vərəsəlik hüququ haqqında şəhadətnamənin verilməsinə dair Ərizə', url: '/assets/documents/document93.docx' },
  { name: 'Xarici dövlətin səlahiyyətli orqanından əmək fəaliyyətinə dair - Arayış', url: '/assets/documents/document94.docx' },
  { name: 'Yaşayış minimumu haqqında', url: '/assets/documents/document95.docx' },
  { name: 'Yaşayış yeri haqqında - Arayış', url: '/assets/documents/document96.docx' },


];

const Documents = () => {
  const { t, i18n } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
 
     <>
         <Heading heading={t("Ərizələr")} title={t("Əsas")} subtitle={t("Ərizələr")} />
         <section class="team-section">
     <div class="auto-container">
     
         {/* <!--Styled Heading--> */}
         
         <div class="row clearfix">
             
         <div className="p-4 w-[80%] mx-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder={t("Axtar...")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border rounded-md"
        />
      </div>
      <div className="file-list space-y-4">
        {filteredFiles.map((file, index) => (
          <div key={index} className="file-item bg-white shadow-md rounded-lg p-4 flex justify-between items-center">
            <div className='flex'> 
            <img src={word}></img>
            <p className="text-lg font-semibold mt-2">{file.name}</p>
            </div>
            <a href={file.url} download>
              <button className="download-button bg-[#086170] text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors">
                {t("Yüklə")}
              </button>
            </a>
          </div>
        ))}
        {filteredFiles.length === 0 && (
          <p className="text-center text-gray-500">{t("Fayl yoxdur")}</p>
        )}
      </div>
    </div>
             
             
             
         </div>
         
     </div>
 </section>
  
         </>
  )
}

export default Documents                        