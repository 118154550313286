import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';


const services = [
  {
    title: "Mühasibatlıq",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "📊", // Muhasebe için uygun
    link: "/muhasibatliq",
  },
  {
    title: "Maliyyə",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "💰", // Finans için uygun
    link: "/maliyye",
  },
  {
    title: "Vergi uçotu",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "💸", // Vergi için uygun
    link: "/vergi-ucotu",
  },
  {
    title: "İnsan resursları",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "👥", // İnsan kaynakları için uygun
    link: "/insan-resurslari",
  },
  {
    title: "Proqramlaşdırma",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "💻", // Programlama için uygun
    link: "/proqramlasdirma",
  },
  {
    title: "E-kassa",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    icon: "📈", // SSAS için uygun
    link: "/e-kassa",
  },
];


const ServicesShort = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-10">
      <div className='w-[80%] mx-auto'>
      <h1 className="text-3xl font-extrabold text-center mb-5 text-[#086170]">{t("Xidmətlərimiz")}</h1>
      <p className="text-center mb-10">{t("Satışlarınızın idarə olunmasını bizə etibar edin")}</p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {services.map((service, index) => (
          <div data-aos="fade-up" key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div className="text-3xl mb-4">{service.icon}</div>
            <h2 className="text-xl font-semibold mb-2">{t(service.title)}</h2>
            <p className="text-gray-700 mb-4">{service.description}</p>
            <a href={service.link} className="text-blue-500 hover:underline">
              {t("Daha Ətraflı")} →
            </a>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default ServicesShort;
