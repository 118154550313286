import React, { useEffect, useState } from "react";
import Heading from "../../Components/home/HeadingProducts";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProductDetail() {

  const { t, i18n } = useTranslation();

  const params = useParams();
  const params2 = Number(params.id);

  const [list, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.inteko.az/inteko/dashboard.php/",
        {
          credentials: 'omit'
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setItems(data.filter(item => item.id === params2));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Heading heading={t("Məhsul detalı")} title={t("Əsas")} title2={t('Məhsullar')} subtitle={t("Məhsul detalı")} />
      <div className='container mx-auto py-12'>
        <div className='flex justify-center'>
          <div className='w-full md:w-4/5 lg:w-3/4 bg-white rounded-lg p-8'>
            {list.map((val, index) => {
              const { id, image, category, name, about, price } = val;
              return (
                <div 
                  className='flex flex-col lg:flex-row items-center' 
                  key={index}
                >
                  <div className='lg:w-1/2 mb-8 lg:mb-0'>
                    <img 
                      src={`data:image/jpeg;base64,${image}`} 
                      alt={name} 
                      className='w-full h-auto object-cover rounded-md' 
                    />
                  </div>
                  <div className='lg:w-1/2 lg:pl-8'>
                    <div className='category mb-4 flex items-center justify-between'>
                      <span className='text-sm font-semibold text-gray-500'>{t(category)}</span>
                      <i className='fa fa-heart text-red-500'></i>
                    </div>
                    <h4 className='text-2xl font-bold mb-4'>{name}</h4>
                    <p className='text-lg text-gray-700 mb-6'>{about}</p>
                    <h4 className="text-lg font-bold text-green-600 mb-4 mt-2 animate-blink">Stokda var</h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

